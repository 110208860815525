import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import BillProductsRow from '@/models/bill/BillProductsRow';
import TableBillProductsState from '../../models/TableBillProductsState';
import SaleService from '@/core/services/sales/SaleService';
import TableBillProductsService from '@/core/services/sales/interfaces/TableBillProductsService';
import SaleInfoWithBillInfo from '@/models/sales/SaleInfoWithBillInfo';
import * as NewBill from '../new-bill/index'
import BillInfoExtra from '@/models/bill/BillInfoExtra';
import FiltersSearchToBill from '@/models/bill/filters/FiltersSearchToBill';
import InfoSale from '@/models/sales/InfoSale';

enum MutationsLocal { 
    RESET_SALE_INFO = "RESET_SALE_INFO"
    , SET_SALE_INFO = "SET_SALE_INFO"
}
@Module({dynamic: true, store, namespaced: true, name: Modules.TableBillProducts})
export default class TableBillProductsModule extends VuexModule implements TableBillProductsState {
    loading = false;
    records = [] as BillProductsRow[]
    filters = {
        folio: ''
    } as FiltersSearchToBill;
    saleInfo: SaleInfoWithBillInfo | null = null;

    get haveInfo(): boolean {
        return this.saleInfo != null;
    }

    get getShowBill(): boolean {
        return this.saleInfo?.stage === "Pagado";
    }

    get getSaleInfo(): InfoSale | null {
        if(this.saleInfo?.isBilled) this.saleInfo.stage = "Facturado"
        return this.saleInfo;
    }
    
    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: BillProductsRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.RESET_TABLE](){
        this.records = [];
    }
    @Mutation
    [MutationsLocal.RESET_SALE_INFO](){
        this.saleInfo = null;
    }
     @Mutation
    [MutationsLocal.SET_SALE_INFO](value: SaleInfoWithBillInfo){
        this.saleInfo = value;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);

         const resp = (await (serviceSale.searchTableBillProducts(this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ?? 
             {} as SaleInfoWithBillInfo
         let rows = [] as BillProductsRow[];
         if (resp?.customer != '') {
             const saleInfo = {...resp, createdAt: new Date(resp.createdAt).toLocaleString(), invoince: resp.invoince+''}
             this.context.commit(MutationsLocal.SET_SALE_INFO, saleInfo);
            this.context.dispatch(Modules.NewBill + '/' + NewBill.Actions.SET_BILL_EXTRA_DATA, {salesId: resp.id}, { root: true });
         }  else this.context.commit(MutationsLocal.RESET_SALE_INFO, resp);

         if (resp.saleDetails) {
            //cambiar el objeto mapeado....
            rows = resp.saleDetails.map(saleDetail => ({
                discount: saleDetail.discount
                , id: saleDetail.productId
                , discountPersent: saleDetail.discountPersent+''
                , measurementUnitName: saleDetail.unitOfMeasurementName
                , name: saleDetail.productName
                , quantity: saleDetail.amount//este nombre dno debe ser amount
                , unitPrice: saleDetail.price
                , tax: saleDetail.tax
                , taxPersent: saleDetail.taxPersent+''//verificar
                , subtotal: saleDetail.price * saleDetail.amount
                , total: saleDetail.total
                , saleDetailId: saleDetail.id
            } as BillProductsRow))

            const extraInfo = {
                customerId: resp.customerId
                , salesId: resp.id
                , paymentMethodId: resp.paymentMethodId ?? undefined
                , regimeId: resp.regimeId ?? undefined
                , typeUseCFDIId: resp.typeUseCFDIId ?? undefined
                , wayToPayId: resp.wayToPayId ?? undefined
            } as BillInfoExtra

            this.context.dispatch(Modules.NewBill + '/' + NewBill.Actions.SET_BILL_EXTRA_DATA, extraInfo, { root: true });
        } 


        this.context.commit(Mutations.SET_TABLE_DATA, rows);
        
       
    }
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: unknown){
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
} 

const serviceSale: TableBillProductsService = new SaleService();