import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2faff9b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-stack pt-15" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "spinner-border spinner-border-sm me-1",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldsBill = _resolveComponent("FieldsBill")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_Form, {
      "validation-schema": _ctx.validationSchema,
      onSubmit: _ctx.saveBill,
      class: "mx-auto w-100 pt-15 pb-10",
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FieldsBill),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            disabled: _ctx.btnBlocker,
            type: "submit",
            class: "btn btn-lg btn-primary me-3"
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t("BUTTONS.SUBMIT")) + " ", 1),
            (_ctx.btnBlocker)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2)
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"])
  ]))
}