
import { computed, defineComponent, onMounted, reactive, ref, watch, onUnmounted } from "vue";
import { Form } from "vee-validate";
import InputText from "@/components/forms/InputText.vue"
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import { getModule } from "vuex-module-decorators";
import TableBillProductsModule from '@/store/modules/bill/modules/table-bill-products'
import FilterFolio from "@/models/general/Paginations/Filters/FilterFolio";
import {formatNumeric} from "@/core/shared/functions/Formatter"
import ModalBillSale from "./components/ModalBillSale.vue"
import BillProductInfo from "@/models/bill/BillProductInfo"
import NewBillModule from "@/store/modules/bill/modules/new-bill";
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";
import emitter from "@/core/emitters";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";
import {VMoney} from 'v-money'
import FiltersSearchToBill from '@/models/bill/filters/FiltersSearchToBill'
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import Loading from "@/components/shared/Loading.vue";
import FieldInfo from "./components/FieldInfo.vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SwalMessageService from "@/core/services/SwalMessageService";
import { SwalIconType } from "@/core/enums/swal-icon-type";
import router from "@/router";
import { RouteNames } from "@/router/route-names";

export default defineComponent({
    components:{
        Form
        , InputText
        //, ModuleMaster
        //, Datatable
        //, Datarow
        , PDFViewer
        , ModalBillSale
        //, NoDataMessage
        , Loading
        , FieldInfo
    }
    , setup(){
        
        const moduleTable = getModule(TableBillProductsModule);
        const moduleForm = getModule(NewBillModule);
        const form = ref();
        const submit = ref<HTMLButtonElement>();
         //son los datos del documento, se reutiliza para cada facturación
        const base64File = ref('');
        const fileName = ref('');
        const openPDF = ref<HTMLButtonElement>(); 

         /**llamado del llenado de los selects */
        moduleForm.getComboWayToPay();
        moduleForm.getComboUseCfdi();
        moduleForm.getComboPaymentTypes();
        moduleForm.getComboRegimes();

        //METHODS
        const searchProducts = () => submit.value?.click();
        const callSubmit = (data: FiltersSearchToBill ) => moduleTable.UPDATE_FILTERS(data) ///////
        const changeSelectionAllItems = (isCheck: boolean) => {
            if(isCheck){
                checkAllRows();
            } else {
                productIdsSelected.value = [];
            }
        }
        const checkAllRows = () => {
            productIdsSelected.value = renderRows.value.map(r => r.ID);
        }
        const isSelectedProduct = (id: string): boolean => {
            //seleccionar todos los elementos
            return productIdsSelected.value.includes(id);
        }
        const addListSelected = (event, id: string) => {
            //seleccionar todos los elementos
            const wantAdd = event.target.checked;
            if(wantAdd && !isSelectedProduct(id)){
                //si se desea agregar
                productIdsSelected.value.push(id);
            } else if(!wantAdd){
                //se quiere quitar
                productIdsSelected.value = productIdsSelected.value.filter(p => p != id)
            }
        }

        //PROPS AND STORE
        const productIdsSelected = ref([] as Array<string>)
        const configTable = reactive([
            { PropName: "num", HeadLabel: "#", Type: "text", VisibleInGrid: true },
            { PropName: "subtotal", HeadLabel: "Subtotal", Type: "moneda", VisibleInGrid: true },        
            { PropName: "tax", HeadLabel: "I.V.A.", Type: "moneda", VisibleInGrid: true },        
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },        
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },        
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        const totalRecords = computed(() => 0);
        const sizePage = computed(() => 0);
        const renderRows = computed(() => formatToDatatable(rows.value, configTable));
        const rows = computed(() => moduleTable.records)
        const loading = computed(() => moduleTable.loading)
        const haveInfo = computed(() => moduleTable.haveInfo)
        const showInfo = computed(() => moduleTable.getShowBill)
        const saleInfo = computed(() => moduleTable.getSaleInfo)
        const allAreSelected = computed(() => productIdsSelected.value.length == renderRows.value.length)
        
        const amountSelected = computed(() => moduleTable.getSaleInfo?.total)
        const productsInfo = computed(() => rows.value.filter(r => productIdsSelected.value.includes(r.id))
            .map( m => ({
                productName: m.name
                , id: m.id
                , amount: m.total
                , saleDetailId: m.saleDetailId
            } as BillProductInfo)))
        
        watch(renderRows, (newValue) => {
            checkAllRows();
        })  
        watch(saleInfo, (newValue) => { 
            if (newValue && newValue.customerId && newValue.customerId != '') {
                moduleForm.SEARCH_BILL_INFO(newValue.customerId);
                moduleForm.SET_AMOUNT_TO_BILL(newValue.total);
            }
        })
        const messageService = new SwalMessageService();

        onMounted(() => {
            /*emitter.on('showBillCreated', (payload) => {
                //mostrar la opcion para abrir la factura en el modulo de creadas
                messageService.confirm(SwalIconType.Success, 'Exito al crear la factura. <br> ¿Desea ir a ver la factura creada para poder descargar sus archivos?')
                .then(isYes => {
                    //enviar mediante el route a la pagina facturas creadas mediante el numBill
                    if (isYes) {
                        router.push({
                            query: {
                                numBill: payload?.billFolio
                            }
                            , name: RouteNames.BillCreated
                        })
                    }

                })
            })*/
            setCurrentPageBreadcrumbs("Factura por Venta", ["Módulos", "Facturación"]);
            moduleForm.SET_IS_BILLING_GLOBAL(false);

        })
        onUnmounted(() => { 
            moduleTable.RESET_SALE_INFO();
        })
        const money = ref(({//en algun momento se usara multilenguaje y aqui pueden aplicar los cambios
            decimal: '.',
            thousands: ',',
            prefix: '$ ',
            suffix: '',
            precision: 2,
            masked: false
        }))

        return{
            searchProducts
            , changeSelectionAllItems
            , isSelectedProduct
            , addListSelected
            , callSubmit
            , totalRecords
            , sizePage
            , headers
            , allAreSelected
            , renderRows
            , productIdsSelected
            , form
            , amountSelected
            , submit
            , productsInfo
            , formatNumeric
            , maskNumber: InputTextMaskTypes.Money
            , loading
            , base64File
            , openPDF
            , fileName
            , money
            , haveInfo
            , showInfo
            , saleInfo
        }
    }
    , directives:{
        money: VMoney
    }
})
