const formatToDatatable = (arrayObj, configColumns, statusPropName = "isActive", idPropName = "", totalPropName = "") => {
    let arrayRows = [];
    if(arrayObj){
        arrayObj.forEach(obj => {
            let objRow = {};
            objRow.ID = obj.id;
            objRow[statusPropName] = obj[statusPropName];
            objRow[idPropName] = obj[idPropName];
            if(totalPropName != "") {
                objRow[totalPropName] = obj[totalPropName];
            }
            objRow.Cells = [];
            configColumns.filter(c => c.VisibleInGrid).forEach(config => {
                let bold = config.Bold ? config.Bold : false;
                let canClicked = config.canClicked ? config.canClicked : false;
                //let badge = config.Badge ? obj[statusPropName] ? 'S' : 'D' : '';
                let badge = config.Badge
                    ? typeof config.Badge === 'function'
                        ? config.Badge(obj)
                        : obj[statusPropName] ? 'S' : 'D'
                    : '';
                let center = config.Center ? config.Center : false;
                objRow.Cells.push({ Name: config.PropName, Value: obj[config.PropName], Type: config.Type, VisibleInGrid: true, Bold: bold, CanClicked: canClicked, Badge: badge, Center: center});
            });
            objRow.originalProps = obj;
            objRow.showSavingSpinner = false;
            arrayRows.push(objRow);
        });
    }
    return arrayRows;
}

export { formatToDatatable }